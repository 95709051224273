import React, { useEffect } from 'react';
import logo from './../logo.svg';

import { TerminalContextProvider, ReactTerminal } from "react-terminal";
import { useKeycloak } from '@react-keycloak/web';

export default function CustomTerminal() {
  const [theme, setTheme] = React.useState("dark");
  const [controlBar, setControlBar] = React.useState(true);
  const [controlButtons, setControlButtons] = React.useState(true);
  const [prompt, setPrompt] = React.useState("$");

  const { keycloak } = useKeycloak();
  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_URI;

  const fetchEndpoint = async (endpoint, queryParams = '') => {
    const response = await fetch(`${API_URL}/${endpoint}${queryParams}`);
    console.log(response);
    return await response.json();
  };

  const commands = {
    help: (
      <span>
        <strong>clear</strong> - clears the console. <br />
        <strong>change_prompt &lt;PROMPT&gt;</strong> - Change the prompt of the
        terminal. <br />
        <strong>change_theme &lt;THEME&gt;</strong> - Changes the theme of the
        terminal. Allowed themes - light, dark, material-light, material-dark,
        material-ocean, matrix and dracula. <br />
        <strong>toggle_control_bar</strong> - Hides / Display the top control
        bar. <br />
        <strong>toggle_control_buttons</strong> - Hides / Display the top
        buttons on control bar. <br /> <br /> <br />
        <strong>getEOProcesses</strong> - Retrieve a list of EO processes.<br />
        <strong>getEOProcessGraphs</strong> - Retrieve a list of EO process graphs.<br />
        <strong>getEOProcessGraphByID &lt;ID&gt;</strong> - Retrieve an EO process graph by its ID.<br />
        <strong>getFileFormats</strong> - Retrieve a list of file formats.<br />
        <strong>submitEODataProcessingRequest</strong> - Submit a request for EO data processing.<br />
        <strong>manageEOJobs </strong> - Manage EO jobs.<br />
        <strong>manageEOJobByID &lt;JOB ID&gt;</strong> - Manage an EO job by its ID.<br />
        <strong>estimateProcessingTimeForJob &lt;JOB ID&gt;</strong> - Estimate processing time for a job.<br />
        <strong>getLogsForJob &lt;JOB ID&gt;</strong> - Retrieve logs for a job.<br />
        <strong>manageResultsForJob</strong> - Manage results for a job.<br />
        <strong>getEOCollections</strong> - Retrieve a list of EO collections.<br />
        <strong>getEOCollectionByID</strong> - Retrieve an EO collection by its ID.<br />
        <strong>getQueryableAttributesForCollection</strong> - Retrieve queryable attributes for a collection.<br />
        <strong>getServiceTypes</strong> - Retrieve a list of service types.<br />
        <strong>manageEOServices</strong> - Manage EO services.<br />
        <strong>manageEOServiceByID</strong> - Manage an EO service by its ID.<br /> <br /> <br />
        <strong>getLogsForService</strong> - Retrieve logs for a service.<br />
        <strong>submitWorkflowToKafka</strong> - Submit a workflow to Kafka.<br />
        <strong>fetchS3Endpoint</strong> - Fetch an endpoint from S3.<br />
        <strong>manageStoreEndpoint</strong> - Manage a store endpoint.<br />
        <strong>manageGroupsEndpoint</strong> - Manage a groups endpoint.<br />
        <strong>getCountOfUsers</strong> - Get the count of users.<br />
        <strong>getCountOfResources</strong> - Get the count of resources.<br />

        {/* <strong>eo4eu</strong> - Access  all EO4EU commands<br /> */}
        <strong>account</strong> - View current account page<br />
        <strong>logout</strong> - Sign out from the console<br />
      </span>
    ),

    eo4eu: (
      <span>
        <p>Usage:  eo4eu [OPTIONS] COMMAND</p><br />
        <strong>getEOProcesses</strong> - Retrieve a list of EO processes.<br />
        <strong>getEOProcessGraphs</strong> - Retrieve a list of EO process graphs.<br />
        <strong>getEOProcessGraphByID &lt;ID&gt;</strong> - Retrieve an EO process graph by its ID.<br />
        <strong>getFileFormats</strong> - Retrieve a list of file formats.<br />
        <strong>submitEODataProcessingRequest</strong> - Submit a request for EO data processing.<br />
        <strong>manageEOJobs </strong> - Manage EO jobs.<br />
        <strong>manageEOJobByID &lt;JOB ID&gt;</strong> - Manage an EO job by its ID.<br />
        <strong>estimateProcessingTimeForJob &lt;JOB ID&gt;</strong> - Estimate processing time for a job.<br />
        <strong>getLogsForJob &lt;JOB ID&gt;</strong> - Retrieve logs for a job.<br />
        <strong>manageResultsForJob</strong> - Manage results for a job.<br />
        <strong>getEOCollections</strong> - Retrieve a list of EO collections.<br />
        <strong>getEOCollectionByID</strong> - Retrieve an EO collection by its ID.<br />
        <strong>getQueryableAttributesForCollection</strong> - Retrieve queryable attributes for a collection.<br />
        <strong>getServiceTypes</strong> - Retrieve a list of service types.<br />
        <strong>manageEOServices</strong> - Manage EO services.<br />
        <strong>manageEOServiceByID</strong> - Manage an EO service by its ID.<br />
        <strong>getLogsForService</strong> - Retrieve logs for a service.<br />
        <strong>submitWorkflowToKafka</strong> - Submit a workflow to Kafka.<br />
        <strong>fetchS3Endpoint</strong> - Fetch an endpoint from S3.<br />
        <strong>manageStoreEndpoint</strong> - Manage a store endpoint.<br />
        <strong>manageGroupsEndpoint</strong> - Manage a groups endpoint.<br />
        <strong>getCountOfUsers</strong> - Get the count of users.<br />
        <strong>getCountOfResources</strong> - Get the count of resources.<br />

      </span>
    ),

    change_prompt: (prompt) => {
      setPrompt(prompt);
    },

    change_theme: (theme) => {
      const validThemes = [
        "light",
        "dark",
        "material-light",
        "material-dark",
        "material-ocean",
        "matrix",
        "dracula",
      ];
      if (!validThemes.includes(theme)) {
        return `Theme ${theme} not valid. Try one of ${validThemes.join(", ")}`;
      }
      setTheme(theme);
    },

    toggle_control_bar: () => {
      setControlBar(!controlBar);
    },

    toggle_control_buttons: () => {
      setControlButtons(!controlButtons);
    },

    evaluate_math_expression: async (expr) => {
      const response = await fetch(
        `https://api.mathjs.org/v4/?expr=${encodeURIComponent(expr)}`
      );
      return await response.text();
    },
    // EO Endpoints
    getEOProcesses: async (limit = '') => {
      return await fetchEndpoint('EO/processes', limit ? `?limit=${limit}` : '');
    },

    getEOProcessGraphs: async (limit = '') => {
      return await fetchEndpoint('EO/process_graph', limit ? `?limit=${limit}` : '');
    },

    getEOProcessGraphByID: async (processGraphID) => {
      return await fetchEndpoint(`EO/process_graphs/${processGraphID}`);
    },

    getFileFormats: async () => {
      return await fetchEndpoint('EO/file_formats');
    },

    submitEODataProcessingRequest: async (requestData) => {
      return await fetch(`${API_URL}/EO/result`, {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    },

    manageEOJobs: async (limit = '') => {
      return await fetchEndpoint('EO/jobs', limit ? `?limit=${limit}` : '');
    },

    manageEOJobByID: async (jobID) => {
      return await fetchEndpoint(`EO/jobs/${jobID}`);
    },

    estimateProcessingTimeForJob: async (jobID) => {
      return await fetchEndpoint(`EO/jobs/${jobID}/estimate`);
    },

    getLogsForJob: async (jobID) => {
      return await fetchEndpoint(`EO/jobs/${jobID}/logs`);
    },

    manageResultsForJob: async (jobID, method = 'GET', data = {}) => {
      return await fetch(`${API_URL}/EO/jobs/${jobID}/results`, {
        method: method,
        body: method !== 'GET' ? JSON.stringify(data) : undefined,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    },

    getEOCollections: async (limit = '') => {
      return await fetchEndpoint('EO/collections', limit ? `?limit=${limit}` : '');
    },

    getEOCollectionByID: async (collectionID) => {
      return await fetchEndpoint(`EO/collections/${collectionID}`);
    },

    getQueryableAttributesForCollection: async (collectionID) => {
      return await fetchEndpoint(`EO/collections/${collectionID}/queryables`);
    },

    getServiceTypes: async () => {
      return await fetchEndpoint('EO/service_types');
    },

    manageEOServices: async (limit = '') => {
      return await fetchEndpoint('EO/services', limit ? `?limit=${limit}` : '');
    },

    manageEOServiceByID: async (serviceID) => {
      return await fetchEndpoint(`EO/services/${serviceID}`);
    },

    getLogsForService: async (serviceID) => {
      return await fetchEndpoint(`EO/services/${serviceID}/logs`);
    },

    // Other Endpoints
    submitWorkflowToKafka: async (workflowData) => {
      return await fetch(`${API_URL}/Kafka/workflow`, {
        method: 'POST',
        body: JSON.stringify(workflowData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    },

    fetchS3Endpoint: async (endpoint, queryParams = '') => {
      return await fetchEndpoint(`S3/${endpoint}`, queryParams);
    },

    manageStoreEndpoint: async (endpoint, method = 'GET', data = {}) => {
      return await fetch(`${API_URL}/Store/${endpoint}`, {
        method: method,
        body: method !== 'GET' ? JSON.stringify(data) : undefined,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    },

    manageGroupsEndpoint: async (endpoint, method = 'GET', data = {}) => {
      return await fetch(`${API_URL}/Groups/${endpoint}`, {
        method: method,
        body: method !== 'GET' ? JSON.stringify(data) : undefined,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    },

    getCountOfUsers: async () => {
      return await fetchEndpoint('Users/count');
    },

    getCountOfResources: async () => {
      return await fetchEndpoint('Resources/count');
    },
    account: () => {
      window.open(keycloak.createAccountUrl())
    },

    logout: () => {
      window.open(keycloak.createLogoutUrl())
    },
  };

  const welcomeMessage = (
    <div style={{ marginBottom: 15 }}>
      <img src={logo} style={{ width: 200 }} />
      <br />
      {/* <pre> 

       /$$$$$$$$  /$$$$$$  /$$   /$$ /$$$$$$$$ /$$   /$$
      | $$_____/ /$$__  $$| $$  | $$| $$_____/| $$  | $$
      | $$      | $$  \ $$| $$  | $$| $$      | $$  | $$
      | $$$$$   | $$  | $$| $$$$$$$$| $$$$$   | $$  | $$
      | $$__/   | $$  | $$|_____  $$| $$__/   | $$  | $$
      | $$      | $$  | $$      | $$| $$      | $$  | $$
      | $$$$$$$$|  $$$$$$/      | $$| $$$$$$$$|  $$$$$$/
      |________/ \______/       |__/|________/ \______/ 

      </pre> */}
      <span>
        Type "help" for all available commands. <br />
      </span>
    </div>
  );

  useEffect(() => {
    keycloak.loadUserInfo()
      .then((response) => {
        console.log(response);
        setPrompt("$" + response.preferred_username)
      })
    //setPrompt("$"+username)
  }, [])


  return (
    <div style={{ textAlign: 'center', height: '100vh' }}>
      <TerminalContextProvider>
        <ReactTerminal
          prompt={prompt}
          theme={theme}
          showControlBar={controlBar}
          showControlButtons={controlButtons}
          welcomeMessage={welcomeMessage}
          commands={commands}
          defaultHandler={(command, commandArguments) => {
            return `${command} is not recognized as an internal or external command. Type 'help' to see all available commands`;
            //return `${command} passed on to default handler with arguments ${commandArguments}`;
          }}
        />
      </TerminalContextProvider>
    </div>
  );
}