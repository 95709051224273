import React from 'react';
import TerminalWrapper from '../wrappers/TerminalWrapper';
import PrivateComponent from '../components/PrivateComponent';
import { Route, Routes } from 'react-router-dom';

export default function MyRoutes(){
    return (
        <Routes>
            <Route path="/" 
            element={
                <PrivateComponent>
                <TerminalWrapper />
                </PrivateComponent>
            } 
            />
            <Route path="/home" 
            element={
                <PrivateComponent>
                <TerminalWrapper />
                </PrivateComponent>
            } 
            />
            <Route path="/console" 
            element={
                <PrivateComponent>
                <TerminalWrapper />
                </PrivateComponent>
            } 
            />
        </Routes>
    )
}